<messages>["./Notification"]</messages>

<template>
  <base-layout mw0>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title class="pb-0">
          <div>
            <div
              v-t="'view.label.send'"
              class="text-h5"/>
            <div
              v-t="'view.label.sendDescription1'"
              class="cgwng-subheading"/>
            <div
              v-t="'view.label.sendDescription2'"
              class="cgwng-subheading"/>
            <div
              v-t="'view.label.sendDescription3'"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <radio-group
                v-model="sendObject.clientNotification"
                :option-values="[true, false]"
                :option-labels="[$t ('view.label.clients'), $t ('view.label.address')]"/>
            </v-col>

            <v-col cols="12">
              <transition
                name="fade"
                mode="out-in">
                <v-row
                  v-if="sendObject.clientNotification"
                  key="clients">
                  <v-col
                    class="d-flex"
                    cols="12">
                    <v-autocomplete
                      v-model="sendObject.groups"
                      :items="clientOptions"
                      :error-messages="validationErrors (
                        'sendObject.groups',
                        {
                          noClients: 'view.label.error.requiredClients'
                        })"
                      :label="$t ('view.label.groups')"
                      multiple
                      @blur="$v.sendObject.groups.$touch ()">
                      <template #selection="data">
                        <v-chip
                          :key="data.item.value"
                          close
                          outlined
                          small
                          color="black"
                          class="my-1"
                          @update:active="removeFromGroups(data.item)">
                          <span
                            class="chipContent"
                            :title="data.item.text">
                            {{ data.item.text }}
                          </span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    class="d-flex"
                    cols="12">
                    <radio-group
                      v-model="sendObject.sendToUsers"
                      :option-values="[false, true]"
                      :option-labels="[$t ('view.label.clientsAddress'), $t ('view.label.userAddress')]"/>
                  </v-col>
                </v-row>

                <v-row
                  v-else
                  key="address">
                  <v-col
                    class="d-flex"
                    cols="12">
                    <multi-email-input
                      ref="addresses"
                      v-model="sendObject.addresses"
                      :name="$t('view.label.address')"
                      :error-messages="validationErrors (
                        'sendObject.addresses',
                        {
                          isSingle: 'view.label.error.requiredAddress',
                        })"
                      :label="$t ('view.label.address')"
                      @blur="$v.sendObject.addresses.$touch ()"/>
                  </v-col>
                </v-row>
              </transition>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model.trim="sendObject.subject"
                :error-messages="validationErrors (
                  'sendObject.subject',
                  {
                    required: 'view.label.error.requiredSubject'
                  })"
                :label="$t ('view.label.subject')"
                @blur="$v.sendObject.subject.$touch ()"/>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="sendObject.message"
                :error-messages="validationErrors (
                  'sendObject.message',
                  {
                    required: 'view.label.error.requiredMessage'
                  })"
                :label="$t ('view.label.message')"
                @blur="$v.sendObject.message.$touch ()"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="`view.label.submit`"
            color="primary"
            :loading="isLoading"
            @click="onSubmit"/>
        </v-card-actions>
        <v-alert
          v-model="success"
          v-t="'view.label.successMessage'"
          type="success"
          dismissible/>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import MultiEmailInput from '@/app/core/components/Inputs/MultiEmailInput'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import RadioGroup from '@/app/core/components/RadioGroup'

  import _cloneDeep from 'lodash/cloneDeep'

  import {mapActions, mapMutations} from 'vuex'

  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import {required} from 'vuelidate/lib/validators'

  export default {
    name: 'SendNotifications',

    components: {
      BaseLayout,
      MultiEmailInput,
      RadioGroup
    },

    mixins: [validationMixins],

    data () {
      return {
        isLoading: false,
        success: false,
        sendObject: {
          clientNotification: true,
          groups: [],
          sendToUsers: false,
          addresses: [],
          subject: '',
          message: ''
        },

        defaultSendObject: {
          clientNotification: true,
          groups: [],
          sendToUsers: false,
          addresses: [],
          subject: '',
          message: ''
        }
      }
    },

    validations: {
      sendObject: {
        subject: {required},
        addresses: {
          isSingle (value) {
            if (!this.sendObject.clientNotification) {
              return value ? value.length > 0 : false
            }

            return true
          }
        },
        message: {required},
        groups: {
          noClients (value) {
            if (this.sendObject.clientNotification) {
              return value ? value.length > 0 : false
            }

            return true
          }
        }
      }
    },

    computed: {
      clientOptions () {
        return ['member', 'reseller', 'partner', 'internal', 'agent'].map ((type) => {
          return {
            text: this.$t (`view.label.clientOptions.${type}`),
            value: type
          }
        })
      }
    },

    watch: {
      'sendObject.type' () {
        this.$v.$reset ()
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      removeFromGroups (item) {
        const index = this.sendObject.groups.findIndex ((val) => {
          return val === item.value
        })

        this.sendObject.groups.splice (index, 1)
      },

      onSubmit () {
        // TODO, FIXME: this is a workaround for https://github.com/vuetifyjs/vuetify/issues/5203
        // (if submitted directly from combobox, then the combobox value is not updated yet, so wait...)
        // This workaround can be reverted as soon as the issue is resolved and the Vuetify library is updated
        setTimeout (() => {
          if (!this.$v.$invalid && this.$refs.addresses.isValid ()) {
            this.isLoading = true

            this.fetchData ({
              op: 'notification/send',
              params: {data: this.sendObject},
              cb: () => {
                this.displaySuccessMessage (this.$t ('view.label.success'))
                this.success = true
                this.resetForm ()
                this.$v.$reset ()
              },
              cbFinal: () => {
                this.isLoading = false
              }
            })
          } else {
            this.$v.$touch ()
            console.log ('invalid')
            this.$refs.addresses.doValidate ()
          }
        })
      },

      resetForm () {
        this.sendObject = _cloneDeep (this.defaultSendObject)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/app/scss/variables';

  .cgwng-subheading {
    margin-top: $_space-md;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
  {
    opacity: 0
  }
</style>
