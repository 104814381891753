var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"mw0":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0",attrs:{"primary-title":""}},[_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:('view.label.send'),expression:"'view.label.send'"}],staticClass:"text-h5"}),_c('div',{directives:[{name:"t",rawName:"v-t",value:('view.label.sendDescription1'),expression:"'view.label.sendDescription1'"}],staticClass:"cgwng-subheading"}),_c('div',{directives:[{name:"t",rawName:"v-t",value:('view.label.sendDescription2'),expression:"'view.label.sendDescription2'"}],staticClass:"cgwng-subheading"}),_c('div',{directives:[{name:"t",rawName:"v-t",value:('view.label.sendDescription3'),expression:"'view.label.sendDescription3'"}],staticClass:"cgwng-subheading"})])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('radio-group',{attrs:{"option-values":[true, false],"option-labels":[_vm.$t ('view.label.clients'), _vm.$t ('view.label.address')]},model:{value:(_vm.sendObject.clientNotification),callback:function ($$v) {_vm.$set(_vm.sendObject, "clientNotification", $$v)},expression:"sendObject.clientNotification"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.sendObject.clientNotification)?_c('v-row',{key:"clients"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.clientOptions,"error-messages":_vm.validationErrors (
                      'sendObject.groups',
                      {
                        noClients: 'view.label.error.requiredClients'
                      }),"label":_vm.$t ('view.label.groups'),"multiple":""},on:{"blur":function($event){_vm.$v.sendObject.groups.$touch ()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{key:data.item.value,staticClass:"my-1",attrs:{"close":"","outlined":"","small":"","color":"black"},on:{"update:active":function($event){return _vm.removeFromGroups(data.item)}}},[_c('span',{staticClass:"chipContent",attrs:{"title":data.item.text}},[_vm._v(" "+_vm._s(data.item.text)+" ")])])]}}],null,false,515411817),model:{value:(_vm.sendObject.groups),callback:function ($$v) {_vm.$set(_vm.sendObject, "groups", $$v)},expression:"sendObject.groups"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('radio-group',{attrs:{"option-values":[false, true],"option-labels":[_vm.$t ('view.label.clientsAddress'), _vm.$t ('view.label.userAddress')]},model:{value:(_vm.sendObject.sendToUsers),callback:function ($$v) {_vm.$set(_vm.sendObject, "sendToUsers", $$v)},expression:"sendObject.sendToUsers"}})],1)],1):_c('v-row',{key:"address"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('multi-email-input',{ref:"addresses",attrs:{"name":_vm.$t('view.label.address'),"error-messages":_vm.validationErrors (
                      'sendObject.addresses',
                      {
                        isSingle: 'view.label.error.requiredAddress',
                      }),"label":_vm.$t ('view.label.address')},on:{"blur":function($event){_vm.$v.sendObject.addresses.$touch ()}},model:{value:(_vm.sendObject.addresses),callback:function ($$v) {_vm.$set(_vm.sendObject, "addresses", $$v)},expression:"sendObject.addresses"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.validationErrors (
                'sendObject.subject',
                {
                  required: 'view.label.error.requiredSubject'
                }),"label":_vm.$t ('view.label.subject')},on:{"blur":function($event){_vm.$v.sendObject.subject.$touch ()}},model:{value:(_vm.sendObject.subject),callback:function ($$v) {_vm.$set(_vm.sendObject, "subject", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"sendObject.subject"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"error-messages":_vm.validationErrors (
                'sendObject.message',
                {
                  required: 'view.label.error.requiredMessage'
                }),"label":_vm.$t ('view.label.message')},on:{"blur":function($event){_vm.$v.sendObject.message.$touch ()}},model:{value:(_vm.sendObject.message),callback:function ($$v) {_vm.$set(_vm.sendObject, "message", $$v)},expression:"sendObject.message"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:("view.label.submit"),expression:"`view.label.submit`"}],attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.onSubmit}})],1),_c('v-alert',{directives:[{name:"t",rawName:"v-t",value:('view.label.successMessage'),expression:"'view.label.successMessage'"}],attrs:{"type":"success","dismissible":""},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }